import React from "react";
import { AxiosService } from "../Services/AxiosService";
import {SESSION, WIDGET_MODE} from "../Constants/Global";
import { isOldSession } from '../Utils/Utils'

/**
 * Hook de manejo de peticiones al servidor
 * @class Hooks/useAxios
 * @example
 * const { data, isLoading } = useAxios("start", {
    session: localStorage.getItem(SESSION),
  });
 * @param {*} type tipo de petición
 * @param {*} value valores de la petición
 * @returns data, error, isLoading
 */
function useAxios(type, value = null) {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  // Forzamos reinicar session si tenemos una vieja (ya borrada)
  if(value && isOldSession(value.session)) {
    value.forceStart = true;
  }

  React.useEffect(() => {
    switch (type) {
      case "start":
        if (value.session && !value.forceStart) {
          AxiosService.getHistory(value.session)
            .then((res) => {
              if((res[1].data.finished_at || res[0].data.type === "employee_line") && localStorage.getItem(SESSION)) {
                window.removeSession();
                setTimeout(window.location.reload, 300);
              }
              res[1] = {
                ...res[1],
                data: {
                  response: res[1].data.historic,
                  session: value.session,
                  progress: res[1].data.progress
                },
              };
              setData(res);
            })
            .catch((err) => {
              setError(err.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          let parsedData = {};
          const data = localStorage.getItem('hrbf.data');
          if(data){
            parsedData = JSON.parse(data);
          }
          AxiosService.getStart(WIDGET_MODE, parsedData)
            .then((res) => {
              setData(res);
            })
            .catch((err) => {
              setError(err.message);
              window.removeSession(-1)
            })
            .finally(() => {
              setIsLoading(false);
            });
        }

        break;
      case "reply":
        if (value.postback && value.session) {
          AxiosService.postReply(value.session, value.postback, value.text)
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => {
              setError(err.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
        break;
      default:
        console.log("Método desconocido");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, isLoading };
}

export default useAxios;
